.side-panel {
  position: fixed;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;

  transition: width 0.5s ease-in-out;
  padding-top: 60px;
  background-color: #fff;
  border-left: 3px solid #007bff;
  box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.3);
  z-index: 2;
}

.side-panel-open {
  width: 300px;
}

.side-panel-content {
  padding: 15px;
}

.side-panel-toggle {
  position: absolute;
  top: 25px;
  left: -50px;
  background-color: #fff;
  border: 3px solid #007bff;
  border-right: 0px;
  outline: none;
  cursor: pointer;
  transition: 0.5s;
  border-radius: 30px 0 0 30px;

  width: 50px;
  height: 40px;
}

.side-panel-content > div {
  margin-bottom: 15px;
}

.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: -99;
}

.side-panel-open + .overlay {
  display: block;
}
