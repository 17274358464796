.fade-enter {
  opacity: 0.01;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinning {
  animation: spin 1s linear infinite;
}

.d-flex.justify-content-center.gy-3.mb-3 {
  flex-direction: column;
}

@media (min-width: 768px) {
  .d-flex.justify-content-center.gy-3.mb-3 {
    flex-direction: row;
  }
}

.icon-left-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px; /* Adjust based on your design preference */
  padding-right: 15px;
}

.icon-left-button .btn-icon {
  flex-shrink: 0; /* Prevents the icon from shrinking */
}

.icon-left-button .btn-text {
  flex-grow: 1; /* Allows the text to take up the maximum available space */
  text-align: center;
}
